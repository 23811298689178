import React from "react"
import Link from '../utils/link'

import Seo from "../components/seo"
import Logo from '../assets/images/logo-white.svg'
import Bg from '../assets/images/bg.jpg'

const IndexPage = () => (
  <>
    <Seo title="Easy Blinds" description="Roller Blinds, Shutters and Curtains." />
		<section className='landing' style={{ backgroundImage: `url(${Bg})` }}>
			<div className='landing__content'>
        <img src={Logo} alt='Easy Blinds' />
        <p>Thanks for visiting us here at Easyblinds. We’re currently in the process of improving our website. For all current order enquiries, please <Link to='hello@easyblinds.com.au'>email us</Link> – we’re here to help.</p>
			</div>
		</section>
  </>
)

export default IndexPage
